import React from 'react'
import "./footer.css"
import LOGO from '../../assets/logo-total.webp'
import { useTranslation } from 'react-i18next'; // Imported useTranslation from react-i18next




const Footer = () => {
  const { t, i18n } = useTranslation('global');

  return (
    <footer>
      <a href="#" className='footer_logo'>
        <img src={LOGO} alt="" />
      </a>

      <ul className='permalinks'>
        <li><a href="#">{t("nav.home")}</a></li>
        <li><a href="#about">{t("nav.about")}</a></li>
        <li><a href="#services">{t("nav.experience")}</a></li>
        <li><a href="#portfolio">{t("nav.services")}</a></li>
        <li><a href="#contact">{t("nav.contact")}</a></li>
      </ul>

      {/* <div className="footer__socials">
       
      </div> */}

      <div className="footer__copyright">
        <small>&copy; noerommes. All rights reserved. </small>
      </div>
    </footer>
  )
}

export default Footer