import React from 'react';
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experiences from "./components/experience/Experience"; // Corrected typo in component import
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Scroll from './components/scroll/Scroll';
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';



export const App = () => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: "es",
    resources: {
      en: {
        global: global_en,
      },
      es: {
        global: global_es,
      }
    }
  });

  return (
    <I18nextProvider i18n={i18next}> 
      <Scroll />
      <Header />
      <Nav />
      <About />
      <Experiences /> 
      <Portfolio/>
      <Services />
      <Contact />
      <Footer />
    </I18nextProvider>
  );
}

export default App;
