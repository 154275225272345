import React, { useState, useEffect } from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { useTranslation } from 'react-i18next'; // Imported useTranslation from react-i18next


const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  const {t} = useTranslation('global');


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const aboutSectionHeight = document.getElementById('about').offsetTop;
      const experienceSectionHeight = document.getElementById('experience').offsetTop;
      const servicesSectionHeight = document.getElementById('services').offsetTop;
      const contactSectionHeight = document.getElementById('contact').offsetTop;

      if (scrollPosition < aboutSectionHeight) {
        setActiveNav('#');
      } else if (scrollPosition < experienceSectionHeight) {
        setActiveNav('#about');
      } else if (scrollPosition < servicesSectionHeight) {
        setActiveNav('#experience');
      } else if (scrollPosition < contactSectionHeight) {
        setActiveNav('#services');
      } else {
        setActiveNav('#contact');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav>
      <div className="nav__container">
        <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>
          <AiOutlineHome />
        </a>
        <p>{t("nav.home")}</p>
      </div>

      <div className="nav__container">
        <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
          <AiOutlineUser />
        </a>
        <p>{t("nav.about")}</p>
      </div>

      <div className="nav__container">
        <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
          <BiBook />
        </a>
        <p>{t("nav.experience")}</p>
      </div>

      <div className="nav__container">
        <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}>
          <RiServiceLine />
        </a>
        <p>{t("nav.services")}</p>
      </div>

      <div className="nav__container">
        <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
          <BiMessageSquareDetail />
        </a>
        <p>{t("nav.contact")}</p>
      </div>
    </nav>
  );
};

export default Nav;
