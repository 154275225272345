import React, { useRef, useState, useEffect } from 'react';
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md"
import { BsWhatsapp } from "react-icons/bs"
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [t] = useTranslation("global");
  const [isSent, setIsSent] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    setIsButtonDisabled(true); // Deshabilita el botón

    emailjs.sendForm('noerommes@noerommes.dev', 'template_vxxu5bb', form.current, 'ucYdIL_-nmx8Ii1Dp')
      .then((result) => {
        console.log(result.status);

        setIsSent(true);

        // Reinicia los valores del formulario
        form.current.reset();

        // Puedes añadir más lógica aquí si es necesario
      }, (error) => {
        console.log(error.text);
      })
      .finally(() => {
        // Vuelve a habilitar el botón después de 3 segundos (puedes ajustar el tiempo según tus necesidades)
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
  };

  useEffect(() => {
    let timer;
    if (isSent) {
      // Configura un temporizador para ocultar el mensaje después de 3 segundos
      timer = setTimeout(() => {
        setIsSent(false);
      }, 5000);
    }

    // Limpia el temporizador al desmontar el componente
    return () => clearTimeout(timer);
  }, [isSent]);

  return (
    <section id='contact'>
      <h5>{t("contact.touch")}</h5>
      <h2>{t("contact.contact")}</h2>
      <div className="container contact__container">
        <div className="contact__options">
            <article className="contact__option">
              <MdOutlineEmail  className='contact__option-icon2'/>
              <h4>Email</h4>
              <h6>noerommes@noerommes.dev</h6>
              <a href="mailto:noerommes@noerommes.dev" target='_blank'  rel="noreferrer">{t("contact.send")}</a>
            </article>

            <article className="contact__option">
              <BsWhatsapp className='contact__option-icon'/>
              <h4>WhatsApp</h4>
              <h5>+34 635 826 266</h5>
              <a href="https://api.whatsapp.com/send?phone=635826266" target='_blank'  rel="noreferrer">{t("contact.send")}</a>
            </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder={t("contact.name")} required/>
          <input type="text" name="subject" placeholder={t("contact.subject")} required />
          <input type="email" name="email" placeholder='Email' required />
          <textarea name="message" rows="7" placeholder={t("contact.message")} required></textarea>
          
          {isSent && (
            <div className="success-message">
              <p>{t("contact.sent")}</p>
            </div>
          )}

          <button type="submit" className='btn btn-primary' disabled={isButtonDisabled}>
            {t("contact.send")}
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact;
