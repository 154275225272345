import React from 'react';
import './header.css';
import CTA from './CTA';
import ME from '../../assets/desarrollador-full-stack-valencia.webp';
import HeaderSocials from './HeaderSocials';
import { useTranslation } from 'react-i18next'; // Imported useTranslation from react-i18next
import { useTypewriter, Cursor } from 'react-simple-typewriter';

const Header = () => {
  const { t, i18n } = useTranslation('global');
  const handleChangeLanguage = (lang) => { 
    i18n.changeLanguage(lang);
  };


  const wordsToType = ["Noé", "Competent", "Creative", "Organized", "Passionate"];
  const translatedWords = wordsToType.map((word) => t(`typewriter.${word}`));

  const [text] = useTypewriter({
    words: translatedWords,
    loop: 0,
  });

  return (
    <header>
      <div className="container header__container">
        <div className="translations__menu">
          <button href="#" className="btn_tra btn__translation btn__es" aria-label="Traducción al español" onClick={() => handleChangeLanguage('es')}>
          </button>
          <button href="#" className="btn_tra btn__translation btn__en" aria-label="Traducción al inglé" onClick={() => handleChangeLanguage('en')}>
          </button>
        </div>
        <h1>{t("header.fs-developer")}</h1>

        <h5>{t("header.hello")}</h5>
        
        <h2>
          {text}
          <span> <Cursor cursorStyle="|" /></span>
        </h2>
        
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={ME} alt="" />
        </div>

        <a href="#contact" className="scroll__down">
        {t("header.scroll")}
        </a>
      </div>
    </header>
  );
};

export default Header;
