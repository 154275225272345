import React, { useState, useEffect } from 'react';
import "./portfolio.css";
import IMG1 from '../../assets/administrador-pacientes-veterinaria-noerommes-desarrollador-full-stack-valencia.webp'
import IMG2 from '../../assets/tiempo-api-proyecto-noerommes-desarrollador-full-stack-valencia.webp'
import IMG3 from '../../assets/modificacion-imagenes-proyecto-noerommes-desarrollador-full-stack-valencia.webp'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import isMobile from 'is-mobile';
import { useTranslation } from 'react-i18next'; // Imported useTranslation from react-i18next




const data = [
  {
    id: 1,
    image: IMG1,
    title: 'adminVetProject',
    github: 'https://github.com/noerommes/vetAdmin',
    demo: 'https://admin-veterinaria-noerommes.netlify.app/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'weatherProject',
    github: 'https://github.com/noerommes/weatherApp',
    demo: 'https://weather-app-noerommes.netlify.app/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'imageEditorProject',
    github: 'https://github.com/noerommes/imageEditor ',
    demo: 'https://image-editor-noerommes.netlify.app/'
  }
];

const Portfolio = () => {
  // Usa el estado local para gestionar la detección de dispositivos móviles
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
  const isDesktop = !isMobileDevice; // Invierte la lógica para desktop

  const { t, i18n } = useTranslation('global');
  const handleChangeLanguage = (lang) => { 
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const handleResize = () => {
      // Actualiza el estado cuando cambia el tamaño de la ventana
      setIsMobileDevice(isMobile());
    };

    // Agrega el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="porfolio-container">
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      {isDesktop ? (
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {data.map(({ id, image, title, github, demo }) => (
            <SwiperSlide key={id}>
              <article className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt="" />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Demo</a>
                </div>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        // Renderiza un tipo de slider diferente para dispositivos móviles
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {data.map(({ id, image, title, github, demo }) => (
            <SwiperSlide key={id}>
              <article className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt="" />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
                </div>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
    </div>
  );
}

export default Portfolio;
