import React from 'react';
import "./experience.css";
import {ImHtmlFive} from "react-icons/im"
import {SiCss3} from "react-icons/si"
import {SiReact} from "react-icons/si"
import {DiSass} from "react-icons/di"
import {FaJava} from "react-icons/fa"
import {TbSql} from "react-icons/tb"
import {FaNodeJs} from "react-icons/fa"
import {IoLogoJavascript} from "react-icons/io"
import { SiTailwindcss } from "react-icons/si";
import { SiExpress } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { useTranslation } from 'react-i18next'; 

const Experience = () => {
  const { t, i18n } = useTranslation('global');
  return (
    <section id='experience'>
      <h5>{t("experience.skills")}</h5>
      <h2>{t("experience.my-experience")}</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>{t("experience.front-development")}</h3>
          <div className="experience__content">
          <article className="experience__details">
                        <ImHtmlFive className="experience__details-icon"/>
                        <div>
                        <h4>HTML</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <SiCss3 className="experience__details-icon"/>
                        <div>
                        <h4>CSS</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <SiReact className="experience__details-icon"/>
                        <div>
                        <h4>REACT</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <SiTailwindcss className="experience__details-icon"/>
                        <div>
                        <h4>TAILWIND CSS</h4>
                        <small className="text-ligth">{t("experience.l-basic")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <DiSass className="experience__details-icon"/>
                        <div>
                        <h4>SASS</h4>
                        <small className="text-ligth">{t("experience.l-basic")}</small>
                        </div>
                      </article>
                      
                      
              
          </div>
        </div>
        <div className="experience__backend">
          <h3>{t("experience.back-development")}</h3>
                    <div className="experience__content">
                    <article className="experience__details">
                        <IoLogoJavascript className="experience__details-icon"/>
                        <div>
                        <h4>JAVSCRIPT</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <FaJava className="experience__details-icon"/>
                        <div>
                        <h4>JAVA</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <TbSql className="experience__details-icon"/>
                        <div>
                        <h4>SQL</h4>
                        <small className="text-ligth">{t("experience.l-intermediate")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <SiMongodb className="experience__details-icon"/>
                        <div>
                        <h4>MONGO DB</h4>
                        <small className="text-ligth">{t("experience.l-basic")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <FaNodeJs className="experience__details-icon"/>
                        <div>
                        <h4>NODE</h4>
                        <small className="text-ligth">{t("experience.l-basic")}</small>
                        </div>
                      </article>
                      <article className="experience__details">
                        <SiExpress className="experience__details-icon"/>
                        <div>
                        <h4>EXPRESS</h4>
                        <small className="text-ligth">{t("experience.l-basic")}</small>
                        </div>
                      </article>
                      
                      
                      
                    </div>
          </div>
      </div>
    </section>
  )
}

export default Experience