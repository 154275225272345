import React from 'react'
import "./services.css"
import {BiCheck} from "react-icons/bi"
import {useTranslation } from 'react-i18next';

const Services = () => {
  const[t] = useTranslation("global");
  return (
    <section id='services'>
      <h5>{t("services.offer")}</h5>
      <h2>{t("services.services")}</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>{t("dam.titulo")}</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dam.tareas-1")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dam.tareas-2")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dam.tareas-3")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dam.tareas-4")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dam.tareas-5")}</p>
            </li>
            
          </ul>
        </article>


        <article className="service">
          <div className="service__head">
            <h3>{t("dsi.titulo")}</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dsi.tareas-1")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dsi.tareas-2")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dsi.tareas-3")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dsi.tareas-4")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("dsi.tareas-5")}</p>
            </li>
            
          </ul>
        </article>


        <article className="service">
          <div className="service__head">
            <h3>{t("daw.titulo")}</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("daw.tareas-1")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("daw.tareas-2")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("daw.tareas-3")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("daw.tareas-4")}</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>{t("daw.tareas-5")}</p>
            </li>
            
          </ul>
        </article>


        



      </div>
    </section>
  )
}

export default Services