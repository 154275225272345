import React from 'react';
import "./about.css";
import ME from "../../assets/desarrollador-full-stack-en-valencia-desarrollador-junior-valencia.webp"
import {FaAward} from "react-icons/fa"
import {VscFolderLibrary} from "react-icons/vsc"
import {useTranslation } from 'react-i18next';

const About = () => {
  const[t] = useTranslation("global");

  return (
    <section id='about'>
      <h5>{t("about.know")}</h5>
      <h2>{t("about.about")}</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward  className='about__icon'/>
              <h5>{t("about.experience")}</h5>
              <small>{t("about.year-working")}</small>
            </article>

            <article className='about__card item-wide'>
              <VscFolderLibrary  className='about__icon'/>
              <h5>{t("about.projects")}</h5>
              <small>{t("about.completed")}</small>
            </article>

          </div>

            <p>{t("about.txt-about")}</p>

            <a href="#contact" className="btn btn-primary">{t("about.talk")}</a>

        </div>
      </div>


    </section>
  )
}

export default About