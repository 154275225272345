import React from 'react'
import {BsLinkedin} from "react-icons/bs"
import {FaGithub} from "react-icons/fa"

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/no%C3%A9-romaguera-meseguer-86179a281/" target='_blank' rel="noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/noerommes" target='_blank' rel="noreferrer"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials